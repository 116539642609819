<template>
  <div>
    <van-nav-bar left-arrow left-text="返回" @click-left="goBack" title="师傅报完工" />
    <div>
      <van-tabs v-model="activeName" color='#428dff' @change='getList'>
        <van-tab title="日报" name="day">
          <div @click="dayFn(v.ddate)" v-for="(v,i) in list" :key="i" style="padding:16px;background-color:#fff;margin-top:10px">
            <div>{{v.ddate}}</div>
            <van-row style="text-align:center" type='flex' justify='space-around'>
              <van-col span="12">
                <div class="title">安装工单量</div>
                <div class="num">{{v.orderct}}</div>
                <div class="title">较上周对比</div>
                <div v-if="v.szazgdl<0" class="downStyle" style="color:#d8210a"><img src='../../assets/icon/down.png'>{{-v.szazgdl}}%</div>
                <div v-if="v.szazgdl>=0" class="upStyle"><img src='../../assets/icon/shangsheng.png'>{{v.szazgdl}}%</div>
              </van-col>
              <van-col span="12">
                <div class="title">设备数</div>
                <div class="num">{{v.machinect}}</div>
                <div class="title">较上周对比</div>
                <div v-if="v.szsbs<0" class="downStyle" style="color:#d8210a"><img src='../../assets/icon/down.png'>{{-v.szsbs}}%</div>
                <div v-if="v.szsbs>=0" class="upStyle"><img src='../../assets/icon/shangsheng.png'>{{v.szsbs}}%</div>
              </van-col>

            </van-row>
          </div>
        </van-tab>
        <van-tab title="月报" name="month">
          <div @click="monthFn(v.ddate)" v-for="(v,i) in list" :key="i" style="padding:16px;background-color:#fff;margin-top:10px">
            <div>{{v.ddate}}</div>
            <van-row style="text-align:center">
              <van-col span="12">
                <div class="title">安装工单量</div>
                <div class="num">{{v.orderct}}</div>
                <div class="title">较上月对比</div>
                <div v-if="v.syazgdl<0" class="downStyle" style="color:#d8210a"><img src='../../assets/icon/down.png'>{{-v.syazgdl}}%</div>
                <div v-else class="upStyle"><img src='../../assets/icon/shangsheng.png'>{{v.syazgdl}}%</div>
              </van-col>
              <van-col span="12">
                <div class="title">设备数</div>
                <div class="num">{{v.machinect}}</div>
                <div class="title">较上月对比</div>
                <div v-if="v.sysbs<0" class="downStyle" style="color:#d8210a"><img src='../../assets/icon/down.png'>{{-v.sysbs}}%</div>
                <div v-else class="upStyle"><img src='../../assets/icon/shangsheng.png'>{{v.sysbs}}%</div>
              </van-col>
            </van-row>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <van-overlay :show="isshow">
      <div class="wrapper">
        <van-loading color="#0094ff" size="24px" vertical>加载中...</van-loading>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { getUrlKey } from "@/utils/common";
export default {
  data() {
    return {
      list: [],
      listMonth: [],
      startDate: "",
      endDate: "",
      activeName: "day",
      isshow: false,
    };
  },
  created() {
    this.startDate = this.$route.query.start;
    this.endDate = this.$route.query.end;
    this.getList();
    // this.getListMonth();
  },
  methods: {
    getmonthday(year, month) {
      let days = new Date(year, Number(month), 0).getDate();
      return days;
    },
    //月报跳转
    monthFn(value) {
      this.$router.push({
        path: "/masterFinished/installDetailMonth",
        query: {
          time: value,
        },
      });
    },
    //日报跳转
    dayFn(value) {
      this.$router.push({
        path: "/masterFinished/installDetail",
        query: {
          time: value,
        },
      });
    },
    //h获取数据-日报
    async getList() {
      this.isshow = true;
      this.list = [];
      let params = {
        openid: getUrlKey("openid", window.location.href),
        moduleId: "11",
        type: "家用",
        startDate: this.startDate,
        endDate: this.endDate,
        // timeConditionType: "day",
        sumType: 0,
        sumMode: "install.comp",
      };
      if (this.activeName === "day") {
        params.timeConditionType = "day";
      } else {
        let day = this.startDate.split("/");
        let day2 = this.endDate.split("/");
        let days = this.getmonthday(day2[0], day2[1]);
        params.startDate = day[0] + "/" + day[1] + "/01";
        params.endDate = day2[0] + "/" + day2[1] + "/" + days;
        params.timeConditionType = "month";
      }
      let res = await this.postRequest("/wechat-analysis/summarys", params);
      res.data.forEach((v) => {
        let szazgdl = 0;
        let szsbs = 0;
        let syazgdl = 0;
        let sysbs = 0;
        szazgdl = (((v.orderct - v.worder) / v.worder) * 100).toFixed(2) * 1;
        szsbs =
          (((v.machinect - v.wmachine) / v.wmachine) * 100).toFixed(2) * 1;
        syazgdl = (((v.orderct - v.morder) / v.morder) * 100).toFixed(2) * 1;
        sysbs =
          (((v.machinect - v.mmachine) / v.mmachine) * 100).toFixed(2) * 1;
        v.szazgdl = szazgdl;
        v.szsbs = szsbs;
        v.syazgdl = syazgdl;
        v.sysbs = sysbs;
      });
      this.list = res.data.reverse();
      this.isshow = false;
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.title {
  font-size: 12px;
  color: #afafaf;
  margin-bottom: 5px;
  padding-top: 16px;
}
.num {
  font-size: 16px;
  color: #1296db;
  img {
    width: 16px;
    height: 16px;
    vertical-align: bottom;
  }
}
</style>